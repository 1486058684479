@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: transparent;
}

/* Custom loader */

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #af2418; /* red main */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader.hidden {
  display: none;
}
